<template>
    <f7-page name="about">
        <f7-navbar title="About" back-link></f7-navbar>
        <div style="padding: 10px">
            <h1>键盘配置文件管理</h1>
            <p>
                Made by Andy Liu, 2023
            </p>
            <f7-link href="/">返回</f7-link>
        </div>
    </f7-page>
</template>
<script>
    export default {}
</script>