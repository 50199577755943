<template>
    <f7-page name="home">
        <!-- The NavBar -->
        <f7-navbar title="键盘配置文件管理">
            <f7-nav-right>
                <f7-link icon-f7="arrow_down_doc" popover-open=".paste-menu" v-if="clipboard !== null"></f7-link>
                <f7-link icon-f7="wrench" @click="onMore" v-if="editMode"></f7-link>
                <f7-link icon-f7="plus" popover-open=".action-menu" v-if="!editMode"></f7-link>
                <f7-link icon-f7="ellipsis_vertical" popover-open=".popover-menu"></f7-link>
            </f7-nav-right>
            <f7-subnavbar>
                <breadcrumb></breadcrumb>
            </f7-subnavbar>
        </f7-navbar>

        <!-- The paste menu in the navbar -->
        <f7-popover class="paste-menu">
            <f7-list>
                <f7-list-item link="#" popover-close :title="clipboard && clipboard.action === 'CUT' ? '剪贴' : '复制'" @click="onPaste"></f7-list-item>
                <f7-list-item link="#" popover-close title="清空" @click="onClear"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- The action menu in the navbar -->
        <f7-popover class="action-menu">
            <f7-list>
                <f7-list-item link="#" popover-close title="上传文件" @click="uploadFileOpened = true"></f7-list-item>
                <f7-list-item link="#" popover-close title="新建文件" @click="newFileOpened = true"></f7-list-item>
                <f7-list-item link="#" popover-close title="新建文件夹" @click="newFolderOpened = true"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- The NavBar menu -->
        <f7-popover class="popover-menu">
            <f7-list>
                <f7-list-item link="#" @click="onEdit" popover-close :title="editMode ? '退出编辑': '编辑'"></f7-list-item>
                <f7-list-item link="#" @click="onRebuild" popover-close title="重新部署"></f7-list-item>
                <f7-list-item link="/about/" popover-close title="关于"></f7-list-item>
                <f7-list-item link="#" @click="onLogout" popover-close title="注销"></f7-list-item>
            </f7-list>
        </f7-popover>

        <!-- Page content -->
        <f7-list>
            <f7-list-item
                    v-for="file in files" :key="file.name"
                    v-bind:title="file.name"
                    v-bind:footer="$filters.formatDate(file.updated) + ' , ' + $filters.formatSize(file.size)"
                    :swipeout="!editMode"
                    @swipeout:deleted="onDelete(file)"
                    @swipeout:open="swipingOut = true"
                    @swipeout:closed="swipingOut = false"
                    @click="!editMode ? onOpen(file): file._selected = !file._selected"
                    :checkbox="editMode"
                    :checked="file._selected"
                    link="#">
                <template #media>
                    <f7-icon v-bind:f7="formatFileType(file.type)"></f7-icon>
                </template>
                <f7-swipeout-actions right>
                    <f7-swipeout-button @click="onMore(file)">更多</f7-swipeout-button>
                    <f7-swipeout-button @click="onInfo(file)" color="orange">详情</f7-swipeout-button>
                    <f7-swipeout-button delete confirm-text="确认删除吗？">删除</f7-swipeout-button>
                </f7-swipeout-actions>
            </f7-list-item>
        </f7-list>

        <!-- Action buttons -->
        <f7-actions :opened="fileActionsOpened" @actions:closed="fileActionsOpened = false">
            <f7-actions-group>
                <f7-actions-label>选择文件操作：</f7-actions-label>
                <f7-actions-button @click="onRename" v-if="editMode">重命名</f7-actions-button>
                <f7-actions-button @click="onCopy">复制</f7-actions-button>
                <f7-actions-button @click="onCut">剪贴</f7-actions-button>
                <f7-actions-button @click="onDeleteMultiple" v-if="editMode">删除</f7-actions-button>
                <!--<f7-actions-button>Move</f7-actions-button>-->
                <f7-actions-button color="red" @click="onCancel">取消</f7-actions-button>
            </f7-actions-group>
        </f7-actions>

        <!-- A popup with file details. -->
        <f7-popup theme-dark color-theme="orange"
                class="file-details-popup"
                :opened="popupOpened"
                @popup:closed="popupOpened = false">
            <file-details-panel v-bind:selected-file="selectedFile"></file-details-panel>
        </f7-popup>

        <!-- A popup to view a file. -->
        <f7-popup tablet-fullscreen theme-dark color-theme="orange"
                  :animate = "false"
                  class="file-details-popup"
                  :opened="viewFilePanelOpened"
                  @popup:closed="viewFilePanelOpened = false"
        >
            <view-file-panel v-bind:selected-file="selectedFile"></view-file-panel>
        </f7-popup>

        <!-- Form to upload a file. -->
        <f7-sheet  class="upload-file-sheet" :opened="uploadFileOpened" @sheet:closed="uploadFileOpened = false">
            <upload-file-panel></upload-file-panel>
        </f7-sheet>

        <!-- Form to create a new file. -->
        <f7-sheet  class="new-file-sheet" :opened="newFileOpened" @sheet:closed="newFileOpened = false">
           <new-file-panel></new-file-panel>
        </f7-sheet>

        <!-- Form to create a new folder. -->
        <f7-sheet  class="new-folder-sheet" :opened="newFolderOpened" @sheet:closed="newFolderOpened = false">
            <new-folder-panel></new-folder-panel>
        </f7-sheet>

    </f7-page>
</template>
<script>
    import useFileSystem from "../model/useFileSystem";
    import {ref, watch} from "vue";
    import { onMounted } from 'vue';
    import { f7 } from 'framework7-vue';
    import Breadcrumb from "../components/breadcrumb";
    import FileDetailsPanel from "../components/fileDetailsPanel";
    import ViewFilePanel from "../components/viewFilePanel";
    import useAuthentication from "../model/useAuthentication";
    import NewFilePanel from "../components/newFilePanel";
    import NewFolderPanel from "../components/newFolderPanel";
    import UploadFilePanel from "../components/uploadFilePanel";

    const {logout, initializeLogin} = useAuthentication();
    const {files, path, clipboard, listFiles, deleteFiles, renameFile, addToClipboard, clearClipboard, paste, rebuild, getAppSettings} = useFileSystem();

    /** True to open the file details popup. */
    const popupOpened = ref(false);

    /** True to open the file panel. */
    const viewFilePanelOpened = ref(false);

    /** True if performing a swipe out on a file. */
    const swipingOut = ref(false);

    const uploadFileOpened = ref(false);
    const newFileOpened = ref(false);
    const newFolderOpened = ref(false);
    const fileActionsOpened = ref(false);

    /** Keeps track of the selected file, */
    const selectedFile = ref(null);

    /** True if in edit mode. */
    const editMode = ref(false);

    /**
     * Determines the icon for the file.
     * @return {string} The icon to use.
     */
    function formatFileType(type) {
        let icon;
        switch (type) {
            case 'image/jpeg':
            case 'image/png':
            case 'image/gif':
                icon = 'photo';
                break;
            case 'text/plain':
            case 'text/html':
            case 'text/javascript':
            case 'text/css':
            case 'text/markdown':
            case 'text/json':
                icon = 'doc_plaintext';
                break;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/pdf':
                icon = 'doc_richtext';
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                icon = 'doc-chart';
                break;
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                icon = 'tv';
                break;
            case 'application/zip':
                icon = 'archivebox';
                break;
            case 'audio/mpeg':
                icon = 'music_note';
                break;
            case 'video/mp4':
                icon = 'film';
                break;
            case 'folder':
                icon = 'folder';
                break;
            default:
                icon = 'doc';
        }

        return icon;
    }

    /**
     * Opens a file or folder.
     * @param file The selected file.
     */
    function onOpen(file) {
        // Prevent opening the file when in swipe out mode.
        if (swipingOut.value) {
            return;
        }
        if (!file) {
            // If no file was provided we open the root folder.
            listFiles();
        } else if (file.type === 'folder') {
            const folderPath = path.value.join('/') + '/' + file.name;
            listFiles(folderPath);
        } else {
            // View the selected file.
            selectedFile.value = file;
            viewFilePanelOpened.value = true;
        }
    }

    /**
     * Shows details about the selected file.
     * @param file The selected file.
     */
    function onInfo(file) {
        selectedFile.value = file;
        popupOpened.value = true;
    }

    /**
     * Shows more actions for the selected file.
     * @param file The selected file.
     */
    function onMore(file) {
        if (!editMode.value) {
            selectedFile.value = file;  
        } else {
            if (!files.value.find(file => file._selected === true)) {
                return;
            }
        }
        fileActionsOpened.value = true;
    }

    /**
     * Renames the selected file.
     */
    function onRename() {
                var rmFile = files.value.find(f =>  f._selected);
        if (!rmFile) {
            // Just in case.
            return;
        }
        // For now we clear the clipboard. (Only needed when it has the selected file, so needs improvement later.)
        clearClipboard();

        f7.dialog.prompt('请输入新名称', '重命名', (value)=>{
            // On ok.
            console.log('new name = ' + value);
            const filePath = path.value.join('/') + '/' + rmFile.name;
            renameFile(filePath, value).then( () => {
                swipingOut.value = false;
            }).catch(()=>{
                swipingOut.value = false;
            });
        }, () => {
            // On cancel
        }, rmFile.name);
    }

    /**
     * Deletes the selected file.
     * @param file The selected file.
     */
    function onDelete(file) {
        deleteFiles([file.name]).then( () => {
            editMode.value = false;
        }).catch( () => {
            editMode.value = false;
        });
        clearClipboard();
    }

    /**
     * Delete multiple selected files when in edit mode.
     */
    function onDeleteMultiple() {
        const filenames = [];
        files.value.forEach(file => {
            if (file._selected === true) {
                filenames.push(file.name);
            }
        });

        if (filenames.length > 0) {
            f7.dialog.confirm('是否永久删除所选？', '删除', ()=>{
                deleteFiles(filenames).then( () => {
                    editMode.value = false;
                }).catch( () => {
                    editMode.value = false;
                });
                clearClipboard();
            });
        }  
    }

    /**
     * Copies the selected file to the clipboard.
     */
    function onCopy() {
        toClipBoard('COPY');
    }

    /**
     * Cuts the selected file to the clipboard.
     */
    function onCut() {
        toClipBoard('CUT');
    }

    /**
     * Performs copy or cut.
     * @param {string} action COPY or CUT.
     */
    function toClipBoard(action) {
        const folderPath = '/' + path.value.join('/')
        if (selectedFile.value && !editMode.value) {
            addToClipboard(folderPath , [selectedFile.value.name], action);
        } else if (editMode.value) {
            const selectedFiles = [];
            files.value.forEach(file => {
                if (file._selected) {
                    selectedFiles.push(file.name);
                }
            });
            addToClipboard(folderPath, selectedFiles, action);
            editMode.value = false;
        }
        swipingOut.value = false;
    }

    /**
     * Pastes a file here.
     */
    function onPaste() {
        paste();
    }

    /**
     * Clears the clipboard.
     */
    function onClear() {
        clearClipboard();
    }

    /**
     * On edit.
     */
    function onEdit() {
        editMode.value = !editMode.value;
        clearClipboard();
        files.value.forEach(file => {
            file._selected = false;
        });
    }

    /**
     * On cancel (in the action menu)
     */
    function onCancel() {
        editMode.value = false;
        clearClipboard();
        swipingOut.value = false;
        files.value.forEach(file => {
            file._selected = false;
        });
    }

    // When the path changes we must reset the selectedFile otherwise errors will result because file and path don't match.
    watch(() => path.value, () => {
        selectedFile.value = null;
        editMode.value = false;
    });

    export default {
        components: {UploadFilePanel, NewFolderPanel, NewFilePanel, ViewFilePanel, FileDetailsPanel, Breadcrumb},
        props: {
            f7router: Object
        },
        setup(props) {
            onMounted(() => {
                console.log('mounted!');
                // Login if no user, otherwise list the files.
                initializeLogin(()=>{
                    // Load the app settings.
                    getAppSettings().then(()=>{
                        // List files.
                        listFiles();
                    });
                }, () => {
                    props.f7router.navigate('/login');
                })
            });

            /**
             * Logout.
             */
            function onLogout() {
                logout().then(()=>{
                    props.f7router.navigate('/login');
                });
            }

            function onRebuild(){
                const loading = f7.dialog.progress("更新中");
                rebuild().then( (result) => {
                    loading.close();
                    if(result){
                        f7.dialog.confirm('更新配置文件成功，请稍后刷新网站', '恭喜', ()=>{});
                    } else {
                        f7.dialog.confirm('更新配置文件失败，请稍后再试', '失败', ()=>{});
                    }
                }).catch( () => {
                    loading.close();
                    f7.dialog.confirm('更新配置文件出错，请稍后再试', '失败', ()=>{});
                });
            }

            return {
                files,
                path,
                clipboard,
                formatFileType,
                onOpen,
                onDelete,
                onInfo,
                onMore,
                onRename,
                onCopy,
                onCut,
                onPaste,
                onClear,
                onLogout,
                onRebuild,
                popupOpened,
                viewFilePanelOpened,
                selectedFile,
                swipingOut,
                uploadFileOpened,
                newFileOpened,
                newFolderOpened,
                fileActionsOpened,
                editMode,
                onEdit,
                onDeleteMultiple,
                onCancel
            };
        }
    }
</script>
<style scoped>
    .page {
        background-color: #333333;
    }
</style>