<template>
    <f7-page>
        <f7-navbar v-bind:title="selectedFile ? selectedFile.name : ''">
            <f7-nav-right>
                <f7-link popup-close>关闭</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-list>
            <f7-list-item
                    header="大小"
                    v-bind:title="selectedFile ? $filters.formatSize(selectedFile.size): ''"
            ></f7-list-item>
            <f7-list-item
                    header="类型"
                    v-bind:title="selectedFile ? selectedFile.type : ''"
            ></f7-list-item>
            <f7-list-item
                    header="创建时间"
                    v-bind:title="selectedFile ?  $filters.formatDate(selectedFile.created) : ''"
            ></f7-list-item>
            <f7-list-item
                    header="更新时间"
                    v-bind:title="selectedFile ?  $filters.formatDate(selectedFile.updated) : ''"
            ></f7-list-item>
            <f7-list-item
                    header="访问时间"
                    v-bind:title="selectedFile ?  $filters.formatDate(selectedFile.accessed) : ''"
            ></f7-list-item>
            <f7-list-item
                    header="作者"
                    v-bind:title="selectedFile ?  selectedFile.owner : ''"
            ></f7-list-item>
        </f7-list>
    </f7-page>
</template>
<script>
    export default {
        name: 'file-details-panel',
        props: ['selectedFile']
    }
</script>