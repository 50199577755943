<template>
    <f7-page no-toolbar no-navbar no-swipeback login-screen>
        <f7-login-screen-title>键盘配置文件管理后台</f7-login-screen-title>
        <f7-list form>
            <f7-list-input
                    label="用户名"
                    type="text"
                    placeholder="请输入登录用户名"
                    :value="username"
                    @input="username = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                    label="密码"
                    type="password"
                    placeholder="请输入登录用户密码"
                    :value="password"
                    @input="password = $event.target.value"
            ></f7-list-input>
        </f7-list>
        <f7-list>
            <f7-list-button @click="signIn">登录</f7-list-button>
            <f7-block-footer v-if="loginError">登录失败</f7-block-footer>
        </f7-list>
    </f7-page>
</template>
<script>
    import {ref} from 'vue';
    import useAuthentication from "../model/useAuthentication";
    const {login} = useAuthentication();

    export default {
        name: 'login-panel',
        props: {
            f7router: Object,
        },
        setup(props) {
            const username = ref('');
            const password = ref('');
            const loginError = ref(false);

            function signIn() {
                login(username.value, password.value).then(user => {
                    console.log('user', user);
                    loginError.value = false;
                    props.f7router.navigate('/');
                }).catch(err => {
                    console.log('err', err);
                    loginError.value = true;
                });
            }

            return {
                username,
                password,
                loginError,
                signIn,
            }
        }
    }
</script>